.index-header {
  position: relative;
  > .container {
    position: relative;
    z-index: 98; } }
.hero-slider {
  position: relative;
  z-index: 56;
  a {
    &:focus {
      outline: none; } }
  .slick-arrow {
    &.slick-prev {
      left: 1.5rem; }
    &.slick-next {
      right: 1.5rem; } }
  .slick-dots {
    @include sm-down {
      bottom: -2.5rem; }
    > li {
      &.slick-active {
        > button {
          background: $primary;
          opacity: 1; } }
      > button {
        background: #4C4C4E;
        opacity: .24; } } } }
.hero-slider-container {
  // background: #FFF3E0
  position: relative;
  overflow: hidden; }




.banner-group {
  .col-banner {
    flex: 0 0 60%;
    max-width: 60%;
    @include sm-down {
      flex: 0 0 100%;
      max-width: 100%; }
    img {
      height: 100%;
      object-fit: cover; } }
  .col-content {
    flex: 0 0 40%;
    max-width: 40%;
    @include sm-down {
      flex: 0 0 100%;
      max-width: 100%; }
    a {
      color: $white;
      &:hover {
        color: rgba($white, .8);
        text-decoration: none; } }
    h3 {
      font-size: 1.35rem;
      @include sm-down {
        font-size: 1.1rem; }
      &:after {
        content: '';
        display: block;
        width: 38px;
        height: 3px;
        border-radius: 5px;
        background-color: #fff;
        margin: 2rem auto;
        @include sm-down {
          margin: 1rem auto; } } }
    p {
      font-size: 1rem;
      font-weight: 300;
      @include sm-down {
        font-size: .85rem; } } } }

.home-news {
  background: url('../images/news-bg.jpg') no-repeat;
  background-size: cover;
  &.pb-b {
    padding-bottom: 15rem;
    @include sm-down {
      padding-bottom: 8rem; } } }
