section {
  &.section-padding {
    padding: 3rem 0; }
  &.mn-t {
    margin-top: -12rem;
    @include sm-down {
      margin-top: -5rem; } }
  &.section-categories {
    padding-top: 6.8rem;
    padding-bottom: 2rem; } }
.entry-header {
  padding-top: 6.8rem;
  .breadcrumb {
    background: transparent;
    padding: 0 0 1rem;
    margin-bottom: 0; }
  .entry-title {
    font-size: 2.45rem;
    font-weight: 500;
    @include md-down {
      font-size: 1.85rem;
      line-height: 1.36; }
    @include sm-down {
      font-size: 1.65rem;
      line-height: 1.36; } } }
.section-heading {
  margin-bottom: 2.5rem;
  @include sm-down {
    margin-bottom: 1.5rem; }
  .title {
    font-size: 2rem;
    color: $primary;
    @include sm-down {
      font-size: 1.5rem; } }
  .sub-title {
    font-size: 2.2rem;
    color: $secondary;
    font-family: 'PT Serif', serif;
    position: relative;
    display: inline-block;
    @include sm-down {
      font-size: 1.65rem; }
    &:after, &:before {
      content: '';
      display: block;
      width: 30px;
      height: 1px;
      background-color: $secondary;
      position: absolute;
      top: 50%; }
    &:before {
      left: -40px; }
    &:after {
      right: -40px; } } }

.post-content {
  .featured-media {
    @include md-up {
      margin-left: -4vw;
      max-width: calc(100% + 8vw);
      width: calc(100% + 8vw); } }
  .featured-media {
    &.alignwide {
      text-align: center;
      margin-bottom: 4rem;
      margin-top: 4rem; } } }
