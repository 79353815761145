.card {
  &.card-post {
    border: 0;
    background-color: transparent;
    &:hover, &:focus {
      .card-img {
        img {
          transform: scale(1.08); } } }
    .card-img {
      overflow: hidden;
      img {
        transition: transform .5s cubic-bezier(0, 0, 0.44, 1.18); } }
    .card-body {
      color: $gray-700;
      font-size: 14px;
      padding: 1rem 0;
      @include sm-down {
        padding: .85rem .6rem; }
      a {
        &:hover {
          text-decoration: none;
          .card-title {
            color: $secondary; }
          .date {
            color: $gray-900; } } }
      .card-title {
        margin-bottom: 0;
        font-size: 1rem;
        height: 50px;
        line-height: 25px;
        text-overflow: ellipsis;
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        @include sm-down {
          -webkit-line-clamp: 1;
          height: 21px; } }
      .date {
        font-size: .85rem;
        color: $secondary;
        position: static;
        margin-bottom: .5rem;
        @include sm-down {
          margin-bottom: .25rem; } } } } }

