@import url('https://fonts.googleapis.com/css2?family=PT+Serif&display=swap');

body {
  position: relative;
  background-color: $white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Noto Sans TC', Roboto, 'Microsoft JhengHei', 'Helvetica Neue', Arial, sans-serif;
  color: $gray-800;
  &.modal-open {
    padding-right: 0px !important;
    .mm-page {
      position: initial; } } }

// .row
//   +sm-down
//     margin-left: -0.5rem
//     margin-right: -0.5rem
//   > *[class^='col']
//     +sm-down
//       padding-left: 0.5rem
//       padding-right: 0.5rem

// .container, *[class^='container']
//   +sm-down
//     padding-left: 0.5rem
//     padding-right: 0.5rem

%img-ratio {
  overflow: hidden;
  width: 100%;
  height: 0px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0; } }

.color-white {
  color: $white; }
.bg-blue {
  background-color: $blue; }
.bg-green {
  background-color: $green; }
.bg-purple {
  background-color: $purple; }


footer {
  &.page-footer {
    font-size: .95rem;
    color: $gray-600;
    position: relative;
    background: #76c5a9;
    a {
      color: $white; }
    .title {
      font-size: 1.1rem;
      color: #0e1713;
      margin-bottom: 1.5rem;
      font-family: 'PT Serif', serif;
      @include md-down {
        margin-bottom: .6rem; } }
    .footer-menu {
      font-size: .85rem; }
    .footer-below {
      background-color: #51a98a;
      padding: .85rem 0;
      .social-link {
        border-radius: 50%;
        width: 32px;
        height: 32px;
        background-color: $secondary;
        display: block;
        padding: 8px;
        display: flex;
        margin-left: .5rem;
        &:hover {
          background-color: darken($secondary, 10%); } } }
    .footer-content {
      padding: 2.6rem 0 1rem;
      @include sm-down {
        padding: 1rem .5rem; }
      ul {
        li {
          margin-bottom: .85rem;
          line-height: 1.3;
          font-size: .9rem; } }
      .icon {
        width: 36px; }
      .logo {
        width: 168px;
        @include md-down {
          width: 126px; } } }
    .social-share {
      .icon {
        width: 24px;
        display: inline-block; } }
    .copyright {
      font-size: .85rem;
      color: rgba($white, .5); } } }
$logo-width: 180px;
$logo-height: 94px;
$logo-width-sm: 106px;
$logo-height-sm: 44px;
$burger-width: 50px;
$burger-bar-width: 24px;
$navi-bg: transparent;
#page {
  position: relative;
  .sticky-wrapper {
    height: auto !important; }
  .is-sticky {
    .page-header {
      z-index: 899 !important;
      background: #fff;

      // &:after
      //   height: $logo-height
      //   +sm-down
      //     height: calc( #{$logo-height-sm} + .5rem)
      .navbar-brand {
        .branding-image {
          width: calc( #{$logo-width} * .8);
          @include sm-down {
            width: calc( #{$logo-width-sm} * .8); } } }
      .burger-button {
        .burger-menu {
          .bar {
            background: $gray-800; } } } } }
  .page-header {
    display: block;
    background: $navi-bg;
    transition: background .2s ease-out;
    transition-delay: 0.2s;
    padding: .25rem 0;
    z-index: 780;
    .navbar {
      @include md-down {
        justify-content: center;
        padding-left: 0;
        padding-right: 0; } }
    .navbar-brand {
      margin: 0;
      padding: 0;
      transition: all 0.8s $cubic;
      .branding-image {
        width: $logo-width;
        transition: width 0.3s ease-out;
        @include sm-down {
          width: $logo-width-sm; } } }
    .navbar-nav {
      .nav-item {
        padding-left: .5rem;
        padding-right: .5rem;
        &:hover {
          .nav-link {
            color: $primary;
            &::before {
              background: $primary; } } }
        &.active.footer-content {
          .nav-link {
            color: $primary; } }
        .nav-link {
          color: $gray-800; } } }
    .dropdown-menu {
      border: 0;
      box-shadow: 0 0 5px rgba(#000,.4); }
    .dropdown-item {
      &:hover {
        color: $primary; } }
    .burger-button {
      position: absolute;
      height: 30px;
      width: $burger-width;
      cursor: pointer;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.3s $cubic;
      &:focus {
        outline: none;
        box-shadow: none; }
      .burger-menu {
        width: $burger-bar-width;
        height: 17px;
        position: relative;
        display: block;
        .bar {
          width: $burger-bar-width;
          height: 1px;
          display: block;
          position: relative;
          background: $gray-600;
          transition: all 0.3s $cubic;
          transition-delay: 0s;
          &.topBar {
            transform: translateY(0px) rotate(0deg); }
          &.midBar {
            transform: translateY(7px) rotate(0deg);
            opacity: 1; }
          &.btmBar {
            transform: translateY(14px) rotate(0deg); } } } }
    #menu {
      position: relative;
      list-style: none;
      width: 100%;
      height: 0px;
      overflow: hidden;
      transition: height 0.3s $cubic, margin 0.3s $cubic;
      .menu-logo {
        width: 80px;
        margin: 1rem auto;
        opacity: 0;
        visibility: hidden;
        transform: translateY(-100%);
        transition: opacity 0.3s $cubic, transform 0.3s $cubic; }
      > .menu-nav {
        width: 100%;
        padding: 2rem;
        > .menu-title {
          @include md-down {
            margin-bottom: 1.5rem; } } }
      h3 {
        font-size: 1.65rem;
        font-weight: 400;
        @include sm-down {
          font-size: 1.2rem; } }
      li.menu-item {
        margin-top: 5px;
        transform: scale(1.15) translateY(-30px);
        opacity: 0;
        transition: transform 0.5s $cubic, opacity 0.6s $cubic;
        .menu-subitem {
          border-bottom: 1px solid #333; }
        a {
          &.menu-link {
            display: block;
            position: relative;
            color: #FFF;
            font-weight: 100;
            text-decoration: none;
            font-size: 1.2rem;
            line-height: 2.35;
            font-weight: 200;
            width: 100%;
            @include sm-down {
              font-size: 1rem; } } } } }
    &.menu-opened {
      height: 100%;
      background-color: $black;
      transition: all 0.3s ease-in, background 0.5s ease-in;
      transition-delay: 0.25s;
      overflow: auto;
      &:after {
        opacity: 0;
        visibility: hidden; }
      .burger-button {
        transform: rotate(90deg);
        z-index: 889;
        .burger-menu {
          .bar {
            transition: all 0.4s $cubic;
            transition-delay: 0.2s;
            background-color: $white;
            &.topBar {
              transform: translateY(7px) rotate(45deg); }
            &.midBar {
              transform: translateX(0px) rotate(0deg);
              opacity: 0; }
            &.btmBar {
              transform: translateY(5px) rotate(-45deg); } } } }
      #menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        .menu-logo {
          transform: translateY(0);
          opacity: 1;
          visibility: visible; }
        li.menu-item {
          transform: scale(1) translateY(0px);
          opacity: 1; } }
      .icon {
        &.icon-bag {
          transform: translateX(75px);
          transition-delay: 0.3s; } } } } }
