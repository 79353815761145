// slack plugin customize
// http://kenwheeler.github.io/slick/
.slick-slide {
  img {
    width: 100%; } }
.slick-arrow {
  position: absolute;
  top: calc(50% - 2rem);
  text-indent: 999px;
  overflow: hidden;
  width: 50px;
  height: 50px;
  background: transparent;
  border: 0;
  border-radius: 50px;
  z-index: 561;

  &::after {
    content: '';
    display: block;
    border-left: 2px solid $gray-700;
    border-bottom: 2px solid $gray-700;
    border-color: $white;
    transform: rotate(45deg);
    width: 16px;
    height: 16px;
    position: absolute;
    top: 17px;
    left: 20px; }
  &:hover {
    outline: 0;
    background: $primary;
    box-shadow: 0 4px 10px rgba(#000000, .4);
    &::after {
      border-color: $white; } }
  &:focus {
    outline: 0; }
  &.slick-disabled {
    opacity: 0;
    visibility: hidden; }
  &.slick-prev {
    left: 10px; }
  &.slick-next {
    right: 10px;
    &::after {
      right: 20px;
      left: auto;
      transform: rotate(225deg); } } }
.slick-dots {
  margin: 0;
  text-align: center;
  position: absolute;
  bottom: 0px;
  width: 100%;
  > li {
    display: inline-block;
    &.slick-active {
      > button {
        background: $primary;
        width: 40px; } }
    > button {
      width: 15px;
      height: 8px;
      background: transparent;
      border: 0;
      border-radius: 10px;
      padding: 0;
      margin: 0 3px;
      text-indent: -9999px;
      overflow: hidden;
      transition: width .2s ease-in-out;
      &:hover,&:focus,&:active,&.active {
        outline: 0 !important; } } } }

.mm-menu {
  background: darken(#FFF3E0, 3%); }
