// 需要載入的
@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Noto+Sans+TC:wght@300;400;700&display=swap');
@import "functions";
@import "helpers/variables";
@import "helpers/mixin";
@import "helpers/extend";
@import "bootstrap";

// 各個頁面
@import "pages/plugins.sass";
@import "pages/layout.sass";
@import "pages/index.sass";

// 區塊式內容
@import "components/navigation";
@import "components/section";
@import "components/item-card";
@import "components/carts";

// 元件
// @import "components/form-square";
// @import "components/process-steps";
// @import "components/interactive"; // 互動元件
@import "components/custom-bs4.sass"; // Bootstrap 4 調整

// 通用類別
@import "components/utilities";

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://example.com/MaterialIcons-Regular.eot);
  /* For IE6-8 */
  src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    url(https://example.com/MaterialIcons-Regular.woff2) format('woff2'),
    url(https://example.com/MaterialIcons-Regular.woff) format('woff'),
    url(https://example.com/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}