%font-Roboto {
  font-family: Roboto,'Helvetica Neue', 'Microsoft JhengHei', 'Helvetica Neue', sans-serif; }

%word-break-1 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }
// display: -webkit-box; 必须结合的属性 ，将对象作为弹性伸缩盒子模型显示 。
// -webkit-box-orient 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式 。
// text-overflow，可以用来多行文本的情况下，用省略号“...”隐藏超出范围的文本 。
%word-break-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal; }
%word-break-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal; }

%img-ratio {
  overflow: hidden;
  width: 100%;
  height: 0px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0; } }
