.row {
  &.gutters-xs {
    margin-left: -1px;
    margin-right: -1px;
    > [class*="col-"] {
      padding-left: 1px;
      padding-right: 1px; } } }

.img-ratio-1by1 {
  @extend %img-ratio;
  padding-bottom: 100%; }
.img-ratio-2by1 {
  @extend %img-ratio;
  padding-bottom: 50%; }
.img-ratio-4by3 {
  @extend %img-ratio;
  padding-bottom: 75%; }
.img-ratio-4by1 {
  @extend %img-ratio;
  padding-bottom: 25%; }
.img-ratio-16by9 {
  @extend %img-ratio;
  padding-bottom: 56.25%; }

.form-control, .custom-select {
  border-color: $gray-400;
  border-radius: $border-radius;
  &:focus {
    border-color: $gray-900;
    outline: 0;
    box-shadow: 0 0 3px 1px rgba(0,0,0,.2); }
  &.dark-control {
    background: transparent;
    color: $gray-200;
    border-color: $white;
    &:foucs {
      color: $white; } } }
